export const surveyStatedata = [
  { label: "أجاب", value: 1 },
  { label: " لم يجب على الهاتف", value: 2 },
  { label: "رقم غير صحيح", value: 3 },
  { label: "لم يكمل سبر الاراء", value: 4 },
  { label: "رفض الاجابة", value: 5 },
  { label: "إعادة الاتصال ", value: 6 },
];
export const customerType = [
  { label: "حريف", value: 1 },
  { label: "مرافق", value: 2 },
  { label: "إطار", value: 3 },
];

export const heardOf = [
  { label: " زيارة الفرع", value: 6 },
  { label: "رئيس مجموعة", value: 5 },
  { label: "صديق/قريب", value: 4 },
  { label: "انستغرام", value: 3 },
  { label: "فايسبوك", value: 2 },
  { label: "تلفزة", value: 1 },
  { label: "وسيلة أخرى", value: 7 },
];
///First Time Or Not
export const firstTime = [
  { label: "نعم", value: true },
  {
    label: "لا",
    value: false,
  },
];
//How Was your Umrah
export const UmrahState = [
  { label: "ممتازة خير ماللي توقعتها", value: 1 },
  { label: "باهية كيف ما ستنيتها ", value: 2 },
  { label: "متوسطة - مقبولة", value: 3 },
  { label: "خايبة", value: 4 },
];
// how was the Branch
export const branchTreatment = [
  { label: "فرحوا بيك في استقبالهم", value: 1 },
  { label: "معاملة عادية", value: 2 },
  { label: " معاملة خايبة من الموظفين", value: 3 },
];
// how was the Accompanying
export const guide = [
  {
    label:
      "معاملة طيبة و قام بالعمرة و الزيارة متاعكم في افضل الظروف و قدم برشة خدمات",
    value: 1,
  },
  { label: "عمل اللي عليه ", value: 2 },
  { label: "ما تلهاش بيكم و ما قامش بدوره كمرافق", value: 3 },
];
// how was the airline
export const airlineTreatment = [
  { label: "ممتازة", value: 1 },
  { label: "عادية", value: 2 },
  { label: "خايبة", value: 3 },
];
//how was the hotels
export const hotelMakkah = [
  { label: "ممتازة", value: 1 },
  { label: "عادية", value: 2 },
  { label: "خايبة", value: 3 },
];
export const hotelMaddina = [
  { label: "ممتازة", value: 1 },
  { label: "عادية", value: 2 },
  { label: "خايبة", value: 3 },
];
//how was the services in makkah
export const makkahServices = [
  { label: "ممتازة", value: 1 },
  { label: "عادية", value: 2 },
  { label: "خايبة", value: 3 },
];
//how was the services in madinah
export const madinahServices = [
  { label: "ممتازة", value: 1 },
  { label: "عادية", value: 2 },
  { label: "خايبة", value: 3 },
];
//what did you like in kounouz
export const BestInKounouz = [
  { label: "الخدمات و اللهوة", value: 1 },
  { label: "السعر", value: 2 },
  { label: "توفر العمرة في كل الأوقات", value: 3 },
  { label: "كل شيئ", value: 4 },
  { label: "ما عجبني حتى شيئ", value: 5 },
];
// Wanty to go again with kounouz
export const isRecommended = [
  { label: "نعم", value: true },
  {
    label: "لا",
    value: false,
  },
];
export const genders = [
  { label: "Homme", value: "1" },
  { label: "Femme", value: "2" },
];
export const genderLikeBackEnd = [
  { label: "Homme", value: 1 },
  { label: "Femme", value: 2 },
];
export const Situation = [
  { label: "عاديّة", value: 0 },
  { label: "خطيرة", value: 1 },
];
export const GoodOrBadResponse = [
  { label: "ممتازة", value: 1 },
  { label: "عادية", value: 2 },
  { label: "خايبة", value: 3 },
];
export const worstOf = [
  { label: "لا شيئ", value: 0 },
  { label: "التنظيم و الاستقبال", value: 1 },
  { label: "المزارات ", value: 5 },
  { label: "فندق ", value: 2 },
  { label: "المرافق", value: 4 },
  { label: "الاحاطة الدينية ", value: 3 },
];
export const chamberType = [
  {
    label: "مفردة",
    value: 1,
  },
  {
    label: "ثنائي",
    value: 2,
  },
  {
    label: "ثلاثي",
    value: 3,
  },
  {
    label: "رباعي",
    value: 4,
  },
];
export const surveySaoudiState = [
  { label: "أجاب", value: 1 },
  { label: "لم يجب", value: 2 },
];
