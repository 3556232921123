// import { AXIOS, BASE_URL } from "../Config/api.config";
import axios from "axios";
import { Auth_URL } from "../Config/api.config";

export const AuthService = () => {
  const headers = {
    Authorization:
      "Bearer " +
      (localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth")).token
        : ""),
  };
  return {
    login: (data) => axios.post(Auth_URL + "LoginCrm", data),
    //register_admin: (data) => axios.post(Auth_URL + "/register-Admin", data),
    // update_profile: (data) =>
    //   AXIOS.post("Auth/update-profile", data, { headers }),
    // reset_password: (pass) =>
    //   AXIOS.post("Auth/resetPassword", pass, { headers }),
  };
};
