export class FlayersModel {
  mandoubMakkah = "";
  mandoubMadinah = "";
  leader = "";
  airline = "";
  dateOfDeparture = new Date();
  departureAirport = "";
  dateOfReturn = new Date();
  returnAirport = "";
  programMadinah = [""];
  programMakkah = [""];
}
