import React from "react";

const Page1 = ({ model }) => {
  return (
    <div style={{ position: "relative" }}>
      <img
        src="/Page1.jpg"
        alt=""
        style={{ height: "8.3in", width: "5.8in" }}
      />
      <div
        style={{
          position: "absolute",
          top: 94,
          left: 0,
          textAlign: "right",
          width: 310,
          // background: "rgba(0,0,0,0.1)",
        }}
      >
        <p
          style={{
            fontWeight: "Bold",
            color: "#FFFFFF",
            fontSize: 30,
          }}
        >
          {model.mandoubMakkah}
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          top: 158,
          left: 0,
          textAlign: "right",
          width: 300,
          // background: "rgba(0,0,0,0.1)",
        }}
      >
        <p
          style={{
            fontWeight: "Bold",
            color: "#FFFFFF",
            fontSize: 30,
          }}
        >
          {model.mandoubMadinah}
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          top: 250,
          left: 0,
          textAlign: "right",
          width: 378,
          // background: "rgba(0,0,0,0.1)",
        }}
      >
        <span
          style={{
            fontWeight: "Bold",
            color: "white",
            fontSize: 30,
          }}
        >
          {model.leader}
        </span>
      </div>
    </div>
  );
};

export default Page1;
