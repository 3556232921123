import axios from "axios";
// export const Base_URL = "https://localhost:7239/api/OldPel/";
export const Base_URL2 =
  // ""
  "https://financeapi.kounouz.travel/";
// "https://localhost:5001/";

export const Axios = axios.create({ baseURL: Base_URL2 + "api/" });
export const Auth_URL = "https://financeapi.kounouz.travel/api/Auth/";
// export const Auth_URL = "https://localhost:5001/api/Auth/";
