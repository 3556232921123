import React, { useEffect, useState } from "react";
import { Input, InputPicker, Pagination } from "rsuite";
import { createAPIEndpoint } from "../../API/authenticated.request";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { userToAdd } from "../../Models/userToAdd";
import AddEdit from "./AddEdit";
import { useRecoilState } from "recoil";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import Swal from "sweetalert2";

const Users = () => {
  const [filterModel, setfilterModel] = useState({ q: "", take: 20, page: 1 });
  const [Users, setUsers] = useState([]);
  const [model, setmodel] = useState(new userToAdd());
  const [state, setstate] = useRecoilState(exportAddAtom);
  const [totalCount, settotalCount] = useState(0);

  const getUsers = () => {
    createAPIEndpoint("Accounts/CrmUsers")
      .fetchAll()
      .then((res) => {
        setUsers(res.data);
        settotalCount(res.data.length());
      })
      .catch((err) => console.log(err));
  };
  const reset = () => {
    setstate((prev) => ({ ...prev, open: false }));
    setmodel(new userToAdd());
  };
  function containsUppercase(str) {
    return /[A-Z]/.test(str);
  }
  function containsNumbers(str) {
    return /[0-9]/.test(str);
  }
  function containsSymbols(str) {
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
  }

  const addUser = () => {
    if (!containsUppercase(model.password)) return alert("ok");
    if (!containsNumbers(model.password)) return alert("ok numbers");
    if (!containsSymbols(model.password)) return alert("ok symbols");
    if (!model.id)
      createAPIEndpoint("Accounts/withPosition")
        .create(model)
        .then((res) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "تمّ إضافة المستخدم",
            showConfirmButton: false,
            timer: 1500,
          });
          reset();
          getUsers();
        })
        .catch((err) => console.log(err));
    else {
      createAPIEndpoint("Accounts")
        .updateWithId(model.id, model)
        .then((res) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "تمّ تعديل المستخدم",
            showConfirmButton: false,
            timer: 1500,
          });
          reset();
          getUsers();
        })
        .catch((err) => console.log(err));
    }
  };

  const getById = (id) => {
    createAPIEndpoint("Accounts")
      .fetchById(id, model)
      .then((res) => {
        setmodel(res.data);
      })
      .catch((err) => console.log(err));
  };
  const deleteUser = (id) => {
    createAPIEndpoint("Accounts")
      .delete(id)
      .then((res) => {
        getUsers();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "User is deleted",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => getUsers(), []);
  // useEffect(() => console.log(model), [model]);
  return (
    <div>
      <ExportAdd
        noExport
        noimportExcel
        button
        ActionOnClose={reset}
        title={"إضافة مستخدم"}
        AddComponent={<AddEdit model={model} setmodel={setmodel} />}
        save={addUser}
      />
      <div style={{ width: "99%", margin: "auto" }}>
        <Grid
          actionKey="id"
          checkAction={(id) => {
            getById(id);
            setstate((prev) => {
              return { ...prev, open: true };
            });
          }}
          deleteAction={(id) => deleteUser(id)}
          columns={columns}
          noAdvancedActions={true}
          rows={Users}
        />
        <div style={{ padding: 20, background: "#fff" }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={totalCount}
            limitOptions={[10, 20, 50, 100]}
            limit={filterModel.take}
            activePage={filterModel.page}
            onChangePage={""}
            onChangeLimit={(take) => {
              // setfilterModel((prev) => {
              //   return { ...prev, take, page: 1 };
              // });
              // createAPIEndpoint("/Order/Umrah", {
              //   ...filterModel,
              //   take,
              //   page: 1,
              // })
              //   .fetch()
              //   .then((res) => {
              //     //   setdataMootmars(res.data.data);
              //     //   settotalCount(res.data.totalCount);
              //   })
              //   .catch((err) => console.log(err));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
const columns = [
  {
    name: " Name",
    value: "lastName",
    value2: "firstName",
    render: (v1, v2) => {
      return <p style={{ fontSize: 17 }}>{v1 + " " + (v2 ? v2 : "")}</p>;
    },
  },
  {
    name: "UserName",
    value: "userName",
    render: (v) => {
      return <div>{v}</div>;
    },
  },
  {
    name: " Email",
    value: "email",
    render: (v) => {
      return <div>{v}</div>;
    },
  },
  {
    name: " Role",
    value: "position",
    render: (v) => {
      return <div>{v}</div>;
    },
  },
  {
    name: " Téléphone",
    value: "phoneNumber",
    render: (v) => {
      return <div>{v}</div>;
    },
  },
];
