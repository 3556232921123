import moment from "moment/moment";
import React, { useEffect, useState } from "react";

const Page2 = ({ model }) => {
  const [date, setdate] = useState(moment(model.dateOfDeparture).format("D"));
  const [Month, setMonth] = useState(
    monthNames[moment(model.dateOfDeparture).format("MM") - 1]
  );

  // useEffect(() => {
  //   setMonth(moment(model.dateOfDeparture).format("D")),
  //     setdate(monthNames[moment(model.dateOfDeparture).format("MM") - 1]);
  // }, [model.dateOfDeparture, model.dateOfReturn]);

  return (
    <div style={{ position: "relative" }}>
      <img
        src="/Page2.png"
        alt=""
        style={{ height: "8.3in", width: "5.8in" }}
      />

      <div
        className="program"
        style={{
          position: "absolute",
          top: 33,
          left: 0,
          width: 360,
          textAlign: "right",
        }}
      >
        <span
          style={{
            fontWeight: "Bold",
            fontSize: 30,
            color: "#d8af40",
          }}
        >
          {moment(model.dateOfDeparture).format("D")}
        </span>
      </div>
      <div
        className="program"
        style={{
          position: "absolute",
          top: 35,
          left: 0,
          width: 323,
          textAlign: "right",
        }}
      >
        <span style={{ fontWeight: "Bold", fontSize: 35, color: "#d8af40" }}>
          {monthNames[moment(model.dateOfDeparture).format("MM") - 1]}
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          top: 45,
          left: 0,
          width: 420,
          textAlign: "right",
        }}
      >
        <p style={{ fontWeight: "Bold", fontSize: 25, color: "#196086" }}>
          <i>{model.airline} </i>
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          top: 123,
          right: 128,
          width: 420,
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <span
          style={{
            color: "#71a5cb",
            fontWeight: "bold",
            marginRight: 3,
            fontSize: 15,
          }}
        >
          {monthNames[moment(model.dateOfDeparture).format("MM") - 1]}
        </span>
        <span style={{ color: "#71a5cb", fontWeight: "bold", fontSize: 15 }}>
          {moment(model.dateOfDeparture).format("D")}
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          top: 130,
          right: 128,
          width: 420,
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <p style={{ color: "#71a5cb", fontWeight: "bold", fontSize: 15 }}>
          {model.departureAirport}
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          top: 169,
          right: 120,
          width: 420,
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <span
          style={{
            color: "#71a5cb",
            fontWeight: "bold",
            marginRight: 3,
            fontSize: 15,
          }}
        >
          {monthNames[moment(model.dateOfReturn).format("MM") - 1]}
        </span>
        <span style={{ color: "#71a5cb", fontWeight: "bold", fontSize: 15 }}>
          {moment(model.dateOfReturn).format("DD")}
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          top: 179,
          right: 120,
          width: 420,
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <p style={{ color: "#71a5cb", fontWeight: "bold", fontSize: 15 }}>
          {model.returnAirport}
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          right: 23,
          top: 270,
          maxWidth: 250,
          overflow: "hidden",
        }}
      >
        {model.programMadinah &&
          model.programMadinah.map((program, index) => {
            return (
              <div style={{ marginBottom: 5 }}>
                <span
                  style={{
                    color: "#daa224",
                    display: "flex",
                    gap: 5,
                    alignItems: "center",
                    justifyContent: "right",
                    fontSize: 17,
                    fontWeight: "bold",
                  }}
                >
                  <span>: {Month}</span>

                  <span>{parseInt(date) + index}</span>

                  {days[index]}

                  <span> اليوم </span>
                  <img src="./logokaaba.jpg" alt="" width={25} />
                </span>
                <div style={{ textAlign: "right" }}>
                  <span
                    className="program"
                    style={{
                      fontSize: 15,
                      color: "#196086",
                      fontWeight: "bold",
                      marginRight: 50,
                    }}
                  >
                    {program}
                  </span>
                </div>
              </div>
            );
          })}
      </div>

      <div
        style={{
          position: "absolute",
          left: 60,
          top: 270,
          maxWidth: 250,
          overflow: "hidden",
        }}
      >
        {model.programMakkah &&
          model.programMakkah.map((program, index) => {
            return (
              <div style={{ marginBottom: 5 }}>
                <span
                  style={{
                    color: "#daa224",
                    display: "flex",
                    gap: 5,
                    alignItems: "center",
                    justifyContent: "right",
                    fontSize: 17,
                    fontWeight: "bold",
                  }}
                >
                  <span>: {Month}</span>

                  <span>
                    {parseInt(date) + model.programMadinah.length + index}
                  </span>

                  {days[index]}

                  <span> اليوم </span>
                  <img src="./logokaaba.jpg" alt="" width={25} />
                </span>

                <div style={{ textAlign: "right" }}>
                  <span
                    className="program"
                    style={{
                      fontSize: 15,
                      color: "#196086",
                      fontWeight: "bold",
                      marginRight: 50,
                    }}
                  >
                    {program}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Page2;
const monthNames = [
  "جانفي",
  "فيفري",
  "مارس",
  "أفريل",
  "ماي",
  "جوان",
  "جويلية",
  "أوت",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];
const days = [
  "الأوّل",
  "الثّاني",
  "الثالث",
  "الرّابع",
  "الخامس",
  "السّادس",
  "السّابع",
  "الثّامن",
  "التّاسع",
  "العاشر",
];
