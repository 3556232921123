import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";
import { Input, InputPicker, Pagination } from "rsuite";
import Swal from "sweetalert2";
import { createAPIEndpoint } from "../../API/authenticated.request";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import { surveyStateAtom } from "../../Atoms/surveyState.atom";
import { userCredentials } from "../../Atoms/userCredentials.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid/index";
import Responsive from "../../Components/Responsive";
import { Base_URL2 } from "../../Config/api.config";
import { CustomerModel } from "../../Models/Customer";
import { MadinahSurveyModel } from "../../Models/MadinahSurveyModel";
import { surveyStatedata } from "../../data/data";
import AddEdit from "./AddEdit";
import AddSurvey from "./AddSurvey";

const SurveyMadinah = () => {
  const [dataMootmars, setdataMootmars] = useState([]);
  const [dates, setdates] = useState([]);
  const [clients, setclients] = useState([]);
  const [state, setstate] = useRecoilState(exportAddAtom);
  const [model, setmodel] = useState(new CustomerModel());
  const [user, setuser] = useRecoilState(userCredentials);

  //------------------------------------------------------------------------------------------------//
  const [SurveyModel, setSurveyModel] = useState(new MadinahSurveyModel());
  const [Error, setError] = useState("");
  const [filterModel, setfilterModel] = useState({
    q: "",
    disponibilityId: 0,
    seasonId: 0,
    clientId: 0,
    page: 1,
    take: 100,
    // methodOfHearingAbout: 0,
    // isFirstTime: false,
    // generalReview: 0,
    // branchReview: 0,
    // guideReview: 0,
    // airlineReview: 0,
    // madinahHotelReview: 0,
    // madinahServiceReview: 0,
    // makkahHotelReview: 0,
    // makkahServiceReview: 0,
    // bestOf: 0,
    // isRecommended: false,
    // dateVole: "",
    // guideName: "",
    // hotelMakkah: "",
    // hotelMadinah: "",
    // isCouponUsed: false,
    // note: "",
  });

  const [surveyState, setsurveyState] = useRecoilState(surveyStateAtom);
  const [totalCount, settotalCount] = useState(0);
  const [Seasons, setSeasons] = useState([]);
  const [GuideNumbers, setGuideNumbers] = useState([]);
  const checkGuide = (v) => {
    if (GuideNumbers.includes(v.phoneNumber)) {
      return { color: "red", fontSize: 17 };
    } else {
      return { color: "green", fontSize: 17 };
    }
  };
  const CustomerType = (v) => {
    if (v == 1)
      return (
        <p
          style={{
            backgroundColor: "green",
            color: "white",
            textAlign: "center",
            padding: 1,
            borderRadius: 5,
            fontSize: 17,
          }}
        >
          Client
        </p>
      );
    else if (v == 2)
      return (
        <p
          style={{
            backgroundColor: "orange",
            color: "white",
            textAlign: "center",
            padding: 1,
            borderRadius: 5,
            fontSize: 17,
          }}
        >
          Guide
        </p>
      );
    else if (v == 3)
      return (
        <p
          style={{
            backgroundColor: "red",
            color: "white",
            textAlign: "center",
            padding: 1,
            borderRadius: 5,
            fontSize: 17,
          }}
        >
          Employé
        </p>
      );
  };

  const columns = [
    {
      value: "customer",
      render: (v) => {
        return (
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {
              <img
                src={Base_URL2 + "Uploads/CustomerPictures/" + v.picture}
                height={35}
                width={35}
                style={{
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              />
            }
          </p>
        );
      },
    },
    {
      name: "Nom",
      value: "customer",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{v.name}</p>;
      },
    },
    {
      name: "Type",
      value: "customer",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{CustomerType(v.customerType)}</p>;
      },
    },
    {
      name: "CIN",
      value: "customer",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{v.cin}</p>;
      },
    },
    {
      name: "Date",
      value: "disponibility",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{v ? v.date.slice(0, 10) : ""}</p>;
      },
    },
    {
      name: "Branche",
      value: "client",
      render: (v) => {
        return (
          <p style={branch_Color(v.name.substring(8))}>{v.name.substring(8)}</p>
        );
      },
    },
    {
      name: "Téléphone",
      value: "customer",
      render: (v) => {
        return (
          <p style={checkGuide(v)}>{v.phoneNumber ? v.phoneNumber : "--"}</p>
        );
      },
    },
    {
      name: "Survey",
      value: "surveyMadinahState",
      render: (v) => {
        let x = surveyStatedata.find((el) => el.value == v);
        return (
          <div style={surveyStateColor(v)}>
            <p style={{ fontSize: 17 }}>{x ? x.label : ""}</p>
          </div>
        );
      },
    },
  ];
  const surveyStateColor = (v) => {
    if (v == 1) {
      return {
        backgroundColor: "#85ea2d",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 2) {
      return {
        backgroundColor: "grey",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 3) {
      return {
        backgroundColor: "black",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 4) {
      return {
        backgroundColor: "orange",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 5) {
      return {
        backgroundColor: "red",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    }
  };
  const branch_Color = (v) => {
    if (["Sousse", "SFAX", "MONASTIR", "msaken", "TUNIS"].includes(v)) {
      return { color: "#00cc00", fontSize: 17 };
    } else if (["ARIANA", "JENDOUBA", "MEDNINE", "DJERBA"].includes(v)) {
      return { color: "#0066ff", fontSize: 17 };
    } else if (["MAHDIA", "KAIROUAN"].includes(v)) {
      return { color: "#ff0000", fontSize: 17 };
    } else if ("NABEUL" == v) {
      return { color: "#9900ff", fontSize: 17 };
    } else return { color: "black", fontSize: 17 };
  };
  const getData = () => {
    createAPIEndpoint("/Order/UmrahCrm", filterModel)
      .fetch()
      .then((res) => {
        setdataMootmars(res.data.data);
        settotalCount(res.data.totalCount);
      })
      .catch((err) => console.log(err));
  };
  const reset = () => {
    setError("");
    setmodel(new CustomerModel());
    setSurveyModel(new MadinahSurveyModel());
    setstate((prev) => {
      return { ...prev, open: false, loading: false };
    });
    setsurveyState((prev) => {
      return { ...prev, open: false, loading: false };
    });
  };
  const save = () => {
    setstate((prev) => {
      return { ...prev, open: false, loading: true };
    });
    let modelCopy = { ...model };

    delete modelCopy.disponibility;
    delete modelCopy.client;
    delete modelCopy.price;
    delete modelCopy.packageUmrah;
    createAPIEndpoint("Order/Umrah")
      .update(modelCopy)
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: ". تمّ التغير بنجاح ",
          showConfirmButton: false,
          timer: 1700,
        });
        getData();
        reset();
      })
      .catch((err) => console.log(err));
  };

  const saveSurvey = () => {
    let modelCopy = { ...model };

    delete modelCopy.disponibility;
    delete modelCopy.client;
    delete modelCopy.price;
    delete modelCopy.packageUmrah;
    if (modelCopy.surveyMadinahState == 1) {
      if (!SurveyModel.id) {
        modelCopy = {
          ...modelCopy,

          surveyMadinah: {
            ...SurveyModel,
            orderUmrahId: model.id,
            clientId: model.client.id,
            dateVol: model.disponibility.date,
            guideName: model.guideName,
            hotelMakkah: model.price ? model.price.hotelMakkahName : "",
            hotelMadinah: model.price ? model.price.hotelMadinahName : "",
            createdBy: JSON.parse(localStorage.getItem("auth")).userName,
            createdDate: new Date(),
          },
        };

        createAPIEndpoint("Order/Umrah")
          .update(modelCopy)
          .then((res) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: ". تمّ التغير بنجاح ",
              showConfirmButton: false,
              timer: 1700,
            });
          })
          .then(() => {
            getData();
            reset();
          })
          .catch((err) => console.log(err));
      } else {
        modelCopy = {
          ...modelCopy,
          surveyMadinahId: SurveyModel.id,
          surveyMadinah: {
            ...SurveyModel,
            updatedBy: JSON.parse(localStorage.getItem("auth")).userName,
            updatedDate: new Date(),
          },
        };
        createAPIEndpoint("Order/Umrah")
          .update(modelCopy)
          .then(() => console.log(modelCopy))
          .catch((err) => console.log(err));
        createAPIEndpoint("surveyMadinah")
          .updateWithId(SurveyModel.id, {
            ...SurveyModel,
            updatedBy: JSON.parse(localStorage.getItem("auth")).userName,
            updatedDate: new Date(),
          })
          .then(() => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: ". تمّ التغير بنجاح ",
              showConfirmButton: false,
              timer: 1700,
            });
            reset();
            getData();
          })
          .catch((err) => console.log(err));
      }
    } else if (modelCopy.surveyMadinahState == 2) {
      createAPIEndpoint("Order/Umrah")
        .update(modelCopy)
        .then((res) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: ". تمّ التغير بنجاح ",
            showConfirmButton: false,
            timer: 1700,
          });
        })
        .then(() => {
          getData();
          reset();
        })
        .catch((err) => console.log(err));
    } else
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "!! ضع حالة الاستبيان ",
        showConfirmButton: false,
        timer: 1700,
      });
  };

  const getById = (id) => {
    createAPIEndpoint(`/Order/Umrah`)
      .fetchById(id)
      .then((res) => {
        setmodel(res.data);
        res.data.surveyMadinahState == 1
          ? createAPIEndpoint("/SurveyMadinah/GetByOrderUmrahId")
              .fetchById(id)
              .then((resp) => {
                console.log(res.data);
                setSurveyModel(resp.data);
              })
              .catch((err) => console.log(err))
          : setSurveyModel(new MadinahSurveyModel());
      })
      .catch((err) => console.log(err));
  };
  const getDates = () => {
    createAPIEndpoint("PackageUmrah/getForPrices", {
      q: "",
      disponibilityId: 0,
      page: 1,
      take: 200,
    })
      .fetch()
      .then((res) => {
        setdates(
          res.data.data
            .reduce(
              (accumulator, el) => accumulator.concat(el.disponibilities),
              []
            )
            .map((el) => ({
              label: el.airline
                ? el.date.substring(0, 10) + " -- " + el.airline.fullName
                : "",
              value: el.id,
            }))
        );
      })
      .catch((err) => console.log(err));
  };

  ////------------------------Clients--------------------///
  const getClients = () => {
    createAPIEndpoint("Client/getAll")
      .fetchAll()
      .then((res) => {
        setclients(
          res.data.map((el) => ({
            label: el.name,
            value: el.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };
  /////-------------------- Guide number-----------------/////
  const getGuidesNumbers = () => {
    createAPIEndpoint("Guide/getAll")
      .fetchAll()
      .then((res) => {
        let numbers = [];
        res.data
          .map((el) => {
            return el.phoneNumber;
          })
          .map((el) => (numbers = [...numbers, ...el.split("/")]));

        setGuideNumbers(numbers);
      })

      .catch((err) => console.log(err));
  };
  /////------------- Get Seasons ----------------/////
  const getSeasons = () => {
    createAPIEndpoint("Season", { page: 1, take: 1000 })
      .fetch()
      .then((res) => {
        setSeasons(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
    getClients();
    getDates();
    getSeasons();
    getGuidesNumbers();
  }, []);
  const handleDelete = (id) => {
    if (JSON.parse(localStorage.getItem("auth")).role == "AdminCrm")
      createAPIEndpoint("SurveyMadinah")
        .delete(id)
        .then((res) => getData())
        .catch((err) => console.log(err));
    else
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Not allowed !! ",
        showConfirmButton: false,
        timer: 1500,
      });
  };

  return (
    <React.Fragment>
      <div style={{ width: "99%", margin: "auto", marginTop: "12px" }}>
        <Filter
          search={getData}
          // advanced
          // advancedComponent={
          //   <AdvancedSearch
          //     filterModel={filterModel}
          //     _setfilterModel={setfilterModel}
          //     advancedData={surveyTitles}
          //   />
          // }
        >
          <Responsive l={3} xl={3} className="p-10">
            <label>Nom ou Téléphone: </label>
            <Input
              value={filterModel.q}
              placeholder="Rechercher"
              onChange={(q) => {
                setfilterModel((prev) => {
                  return { ...prev, q, page: 1 };
                });
              }}
            />
          </Responsive>
          <Responsive l={3} xl={3} className="p-10">
            <label> Date de Vol: </label>
            <InputPicker
              block
              placeholder="Rechercher"
              data={dates}
              onChange={(disponibilityId) => {
                setfilterModel((prev) => {
                  return { ...prev, disponibilityId, page: 1 };
                });
              }}
            />
          </Responsive>
          <Responsive l={3} xl={3} className="p-10">
            <label>Saison: </label>
            <InputPicker
              block
              data={[{ label: "Tout", value: 0 }].concat(
                Seasons.map((c) => {
                  return { label: c.designation, value: c.id };
                })
              )}
              value={filterModel.seasonId}
              placeholder="Rechercher"
              onChange={(seasonId) => {
                setfilterModel((prev) => {
                  return { ...prev, seasonId, page: 1 };
                });
              }}
            />
          </Responsive>
          <Responsive l={3} xl={3} className="p-10">
            <label> Agence: </label>
            <InputPicker
              block
              placeholder="Rechercher"
              data={clients}
              onChange={(clientId) => {
                setfilterModel((prev) => {
                  return { ...prev, clientId, page: 1 };
                });
              }}
            />
          </Responsive>
        </Filter>
      </div>

      <ExportAdd
        ActionOnClose={reset}
        save={save}
        saveSurvey={saveSurvey}
        size="full"
        noExport
        noimportExcel
        AddComponent={
          <AddEdit error={Error} model={model} _setmodel={setmodel} />
        }
        AddsurveyComponent={
          <AddSurvey
            selectedMootamar={model}
            setselectedMootmar={setmodel}
            model={SurveyModel}
            _setmodel={setSurveyModel}
          />
        }
      />

      <div style={{ width: "99%", margin: "auto" }}>
        <Grid
          actionKey="id"
          checkAction={(id) => {
            getById(id);
            setstate((prev) => {
              return { ...prev, open: true };
            });
          }}
          deleteAction={(id) => {
            handleDelete(id);
          }}
          addServeyAction={(id) => {
            getById(id);
            setsurveyState((prev) => {
              return { ...prev, open: true };
            });
          }}
          columns={columns}
          noAdvancedActions={true}
          rows={dataMootmars}
        />
        <div style={{ padding: 20, background: "#fff" }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={totalCount}
            limitOptions={[10, 20, 50, 100]}
            limit={filterModel.take}
            activePage={filterModel.page}
            onChangePage={(page) => {
              setfilterModel((prev) => {
                return { ...prev, page };
              });
              createAPIEndpoint("/Order/Umrah", {
                ...filterModel,
                page,
              })
                .fetch()
                .then((res) => {
                  setdataMootmars(res.data.data);
                  settotalCount(res.data.totalCount);
                })
                .catch((err) => console.log(err));
            }}
            onChangeLimit={(take) => {
              setfilterModel((prev) => {
                return { ...prev, take, page: 1 };
              });
              createAPIEndpoint("/Order/Umrah", {
                ...filterModel,
                take,
                page: 1,
              })
                .fetch()
                .then((res) => {
                  setdataMootmars(res.data.data);
                  settotalCount(res.data.totalCount);
                })
                .catch((err) => console.log(err));
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SurveyMadinah;
