export const Colors = [
  "#e74c3c",
  "#7f8c8d",
  "#27ae60",
  "#2980b9",
  "orange",
  "Purple",
  "Teal",
];

export const Titles = [
  "التنظيم قبل الرحلة في مطار تونس و في طيّارة",
  "التنظيم و الاستقبال في المطار عند الوصول الى السعودية",
  "المرافق",
  "مزارات المدينة",
  "الاحاطة الدينية",
  " فندق المدينة",
  "أسوء الخدمات في المدينة",
];
export const worstColors = [
  "#26de81",
  "#7f8c8d",
  "#fa8231",
  "#4b7bec",
  "orange",
  "Purple",
  "Teal",
];
