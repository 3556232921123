import PageTopIcon from "@rsuite/icons/PageTop";
import React, { useEffect } from "react";
import { Checkbox, Input, InputPicker } from "rsuite";
import Responsive from "../../Components/Responsive";
import { Base_URL2 } from "../../Config/api.config";
import {
  chamberType,
  GoodOrBadResponse,
  surveySaoudiState,
  worstOf,
} from "../../data/data";

const AddSurvey = ({
  model,
  _setmodel,
  selectedMootamar,
  setselectedMootmar,
}) => {
  useEffect(() => console.log(selectedMootamar), [selectedMootamar]);
  return (
    <>
      <div
        className=" p-10"
        style={{
          borderRadius: "10px",
        }}
      >
        <Responsive
          l={3}
          xl={3}
          // className="p-10"
          style={{
            height: 250,
          }}
        >
          <img
            src={
              Base_URL2 +
              "Uploads/CustomerPictures/" +
              selectedMootamar.customer.picture
            }
            width={"100%"}
            height={"100%"}
          />
        </Responsive>
        <Responsive
          l={9}
          xl={9}
          className="p-10"
          style={{
            background: "#7bed9f",
            //  borderRadius: "10px",
          }}
        >
          <Responsive className="p-10">
            {/* <span
              style={{
                marginRight: 10,
                fontWeight: "bolder",
                fontSize: 20,
              }}
            >
              Nom:
            </span> */}
            <span style={{ fontSize: 25, fontWeight: "bolder" }}>
              {selectedMootamar.customer.name}
            </span>
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>Age :</p>
            <Input
              placeholder="Age "
              value={selectedMootamar.customer.age}
              onChange={(age) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: {
                    ...selectedMootamar.customer,
                    age: parseInt(age),
                  },
                }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>
              Numero de téléphone
            </p>
            <Input
              placeholder="Numero de téléphone "
              value={selectedMootamar.customer.phoneNumber}
              onChange={(phoneNumber) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: { ...selectedMootamar.customer, phoneNumber },
                }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>Hotel Makkah :</p>
            <Input
              readOnly
              placeholder="Hotel Makkah : "
              value={selectedMootamar.price.hotelMakkahName}
              onChange={(hotelMakkahName) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: {
                    ...selectedMootamar.price.hotelMakkahName,
                    hotelMakkahName,
                  },
                }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>
              Hotel Madinah :
            </p>
            <Input
              readOnly
              placeholder="Hotel Madinah : "
              value={selectedMootamar.price.hotelMadinahName}
              onChange={(hotelMadinahName) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: {
                    ...selectedMootamar.price.hotelMadinahName,
                    hotelMadinahName,
                  },
                }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>
              Nombre de Chambre
            </p>
            <Input
              placeholder="Nombre de Chambre"
              value={model.chamberNumber}
              onChange={(chamberNumber) =>
                _setmodel((prev) => ({
                  ...prev,
                  chamberNumber: parseInt(chamberNumber),
                }))
              }
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>
              Type de Chambre
            </p>
            <InputPicker
              data={chamberType}
              placeholder="Type de Chambre"
              value={model.chamberType}
              onChange={(chamberType) =>
                _setmodel((prev) => ({ ...prev, chamberType }))
              }
            />
          </Responsive>
        </Responsive>
      </div>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          background: "#ccffcc",
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <span
          style={{
            marginRight: 5,
            fontSize: 18,
            display: "flex",
            justifyContent: "right",
          }}
        >
          تحديد موعد زيارة المعتمرين(قبل مغادرة المدينة بيوم) و التواصل مع رئيس
          المجموعة قبل الاستبيان؟
          <span style={{ marginLeft: 5 }}>
            <PageTopIcon />
          </span>
        </span>
        <span
          style={{
            marginRight: 5,
            fontSize: 18,
            display: "flex",
            justifyContent: "right",
            marginTop: 10,
          }}
        >
          التاكد من عدم وجود اي شخص (بما في ذلك رئيس المجموعة) عيد زيارة
          المعتمرين في غرفهم للاستبيان؟
          <span style={{ marginLeft: 5 }}>
            <PageTopIcon />
          </span>
        </span>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {surveySaoudiState.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space around",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(v) => {
                    setselectedMootmar((prev) => {
                      return { ...prev, surveyMadinahState: v };
                    });
                  }}
                  checked={
                    selectedMootamar.surveyMadinahState == el.value
                      ? true
                      : false
                  }
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كان التنظيم قبل الرحلة في مطار تونس و في طيّارة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(organizationInTunisia) => {
                    _setmodel((prev) => {
                      return { ...prev, organizationInTunisia };
                    });
                  }}
                  checked={
                    model.organizationInTunisia == el.value ? true : false
                  }
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كان التنظيم و الاستقبال في المطار عند الوصول الى السعودية؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(organizationInSaoudi) => {
                    _setmodel((prev) => {
                      return { ...prev, organizationInSaoudi };
                    });
                  }}
                  checked={
                    model.organizationInSaoudi == el.value ? true : false
                  }
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كانت اللهوة بيكم من طرف المرافق في المدينة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(guideReview) => {
                    _setmodel((prev) => {
                      return { ...prev, guideReview };
                    });
                  }}
                  checked={model.guideReview == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كان تقييمك لمزارات المدينة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(madinahPlacesReview) => {
                    _setmodel((prev) => {
                      return { ...prev, madinahPlacesReview };
                    });
                  }}
                  checked={model.madinahPlacesReview == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش تقييمك للاحاطة الدينية في المدينة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(madinahReligiousbriefing) => {
                    _setmodel((prev) => {
                      return { ...prev, madinahReligiousbriefing };
                    });
                  }}
                  checked={
                    model.madinahReligiousbriefing == el.value ? true : false
                  }
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش لقيت فندق المدينة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(madinahHotelReview) => {
                    _setmodel((prev) => {
                      return { ...prev, madinahHotelReview };
                    });
                  }}
                  checked={model.madinahHotelReview == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          شنية الحاجة اللي ما عجبتكش في خدمات كنوز طول فترة اقامتك في المدينة؟
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {worstOf.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(worstOfMadinah) => {
                    _setmodel((prev) => {
                      return { ...prev, worstOfMadinah };
                    });
                  }}
                  checked={model.worstOfMadinah == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      {/* Note */}
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          background: "#ccffcc",
          // borderRadiusBottom: "15px",
          marginBottom: 15,
        }}
      >
        <Input
          as="textarea"
          rows={10}
          value={model.note}
          onChange={(note) =>
            _setmodel((prev) => {
              return { ...prev, note };
            })
          }
        />
      </Responsive>
    </>
  );
};

export default AddSurvey;
