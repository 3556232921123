export class MadinahSurveyModel {
  organizationInTunisia = 0;
  organizationInSaoudi = 0;
  guideReview = 0;
  madinahPlacesReview = 0;
  madinahReligiousbriefing = 0;
  madinahHotelReview = 0;
  worstOfMadinah = 0;
  chamberNumber = 0;
  chamberType = 0;
  note = "";
}
