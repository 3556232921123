import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Input, InputPicker, Pagination } from "rsuite";
import Swal from "sweetalert2";
import { createAPIEndpoint } from "../../API/authenticated.request";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { GuideModel } from "../../Models/Guide";
import AddGuide from "./AddEvent";

const Guide = () => {
  const [Guide, setGuide] = useState([]);
  const [clients, setclients] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    q: "",
    clientId: 0,
    take: 20,
    page: 1,
  });

  const [model, setmodel] = useState(new GuideModel());
  const [state, setstate] = useRecoilState(exportAddAtom);
  ////////////////////////////////////
  const columns = [
    {
      name: "Nom",
      value: "lastName",
      render: (v) => {
        return <p>{v}</p>;
      },
    },
    {
      name: "Prénom",
      value: "firstName",
      render: (v) => {
        return <p>{v}</p>;
      },
    },
    {
      name: "N° de Passport",
      value: "passportNumber",
      render: (v) => {
        return <p>{v}</p>;
      },
    },

    {
      name: "Téléphone",
      value: "phoneNumber",
      render: (v) => {
        return <p>{v}</p>;
      },
    },
    {
      name: "Agence",
      value: "clientId",
      render: (v) => {
        return <p>{clientToShow(v)}</p>;
      },
    },
  ];
  ///////////////////////////////////
  ///////// get guides///////

  const getData = () => {
    createAPIEndpoint("Guide", filterModel)
      .fetch()
      .then((res) => {
        // let data = res.data.reverse();
        // console.log(res);
        setGuide(res.data.data);
        settotalCount(res.data.totalCount);
      })
      .catch((err) => console.log(err));
  };

  /////-------- Save Guide -------- /////
  const save = () => {
    if (!model.id) {
      createAPIEndpoint("Guide")
        .create(model)
        .then(() => {
          getData();
          Swal.fire({
            icon: "success",
            title: "! تّم حفظ المرافق ",
          });
          setstate((prev) => ({ ...prev, open: false }));
        })

        .catch((err) =>
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          })
        );
    } else {
      createAPIEndpoint("Guide")
        .updateWithId(model.id, model)
        .then(() => {
          getData();
          Swal.fire({
            icon: "success",
            title: "! تّم تعديل المرافق ",
          });
          setstate((prev) => ({ ...prev, open: false }));
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
    getData();
  };
  /////-------- get By Id  --------/////
  const getById = (id) => {
    createAPIEndpoint("Guide")
      .fetchById(id)
      .then((res) => {
        setmodel(res.data);
        setstate((prev) => ({ ...prev, open: true }));
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="#">Why do I have this issue?</a>',
        });
      });
  };
  /////-------- Delete Guide --------/////
  const handledelete = (id) => {
    createAPIEndpoint("Guide")
      .delete(id)
      .then(() => {
        getData();
        Swal.fire({
          title: "! تّم فسخ المرافق  ",
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /////--------Get Clients----------/////
  const getClients = () => {
    createAPIEndpoint("Client/getAll")
      .fetchAll()
      .then((res) => {
        setclients(
          res.data.map((el) => ({
            label: el.name,
            value: el.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };
  const clientToShow = (v) => {
    let client = clients.filter((el) => el.value == v);
    if (client.length != 0) return client[0].label;
  };

  const reset = () => {
    setmodel(new GuideModel());
  };

  useEffect(() => {
    getData();
    getClients();
  }, []);
  return (
    <div>
      <Responsive className="p-10">
        <Filter search={getData}>
          <Responsive className="p-10 ">
            <Responsive className="p-10 " l={4} xl={4}>
              <label htmlFor=""> Nom Ou Télphone :</label>
              <Input
                value={filterModel.q}
                onChange={(q) => setfilterModel((prev) => ({ ...prev, q }))}
              />
            </Responsive>
            {/* <Responsive className="p-10 " l={4} xl={4}>
              <label htmlFor=""> Prenom :</label>
              <Input
                value={filterModel.firstName}
                onChange={(firstName) =>
                  setfilterModel((prev) => ({ ...prev, firstName }))
                }
              />
            </Responsive> */}
            {/* <Responsive className="p-10 " l={4} xl={4}>
              <label htmlFor=""> Numéro de Téléphone :</label>
              <Input
                value={filterModel.q}
                onChange={(q) => setfilterModel((prev) => ({ ...prev, q }))}
              />
            </Responsive> */}
            <Responsive className="p-10 " l={4} xl={4}>
              <label htmlFor=""> agence :</label>
              <InputPicker
                block
                data={clients}
                value={filterModel.clientId}
                onChange={(clientId) =>
                  setfilterModel((prev) => ({ ...prev, clientId }))
                }
              />
            </Responsive>
          </Responsive>
        </Filter>
      </Responsive>
      <ExportAdd
        title="اضافة مرافق"
        size="md"
        button
        noExport
        noimportExcel
        ActionOnClose={reset}
        save={save}
        AddComponent={
          <AddGuide model={model} setmodel={setmodel} clients={clients} />
        }
      />

      <Responsive className="p-10">
        <Grid
          actionKey="id"
          noAdvancedActions
          editAction={(id) => {
            getById(id);
          }}
          deleteAction={(id) => handledelete(id)}
          columns={columns}
          rows={Guide}
        />
      </Responsive>
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            setfilterModel((prev) => {
              return { ...prev, page };
            });
            createAPIEndpoint("/Guide", {
              ...filterModel,
              page,
            })
              .fetch()
              .then((res) => {
                setGuide(res.data.data);
              })
              .catch((err) => console.log(err));
          }}
          onChangeLimit={(take) => {
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
            createAPIEndpoint("/Guide", {
              ...filterModel,
              take,
            })
              .fetch()
              .then((res) => {
                setGuide(res.data.data);
              })
              .catch((err) => console.log(err));
          }}
        />
      </div>
    </div>
  );
};

export default Guide;
