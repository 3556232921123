import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";
import { Checkbox, Input, InputPicker, Pagination } from "rsuite";
import Swal from "sweetalert2";
import { createAPIEndpoint } from "../../API/authenticated.request";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import { surveyStateAtom } from "../../Atoms/surveyState.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid/index";
import Responsive from "../../Components/Responsive";
import { Base_URL2 } from "../../Config/api.config";
import { surveyStatedata } from "../../data/data";
import { surveyTitles } from "../../data/filterData";
import { CustomerModel } from "../../Models/Customer";
import { surveyModel } from "../../Models/surveyModel";
import ComponentToPrint from "./ComponentToPrint";
import AddEdit from "./AddEdit";
import AddSurvey from "./AddSurvey";
import AdvancedSearch from "./AdvancedSearch";
import moment from "moment";

const Customer = () => {
  const [dataMootmars, setdataMootmars] = useState([]);
  const [dates, setdates] = useState([]);
  const [clients, setclients] = useState([]);
  const [state, setstate] = useRecoilState(exportAddAtom);
  const [model, setmodel] = useState(new CustomerModel());
  const [GuideNumbers, setGuideNumbers] = useState([]);

  //------------------------------------------------------------------------------------------------//
  const [SurveyModel, setSurveyModel] = useState(new surveyModel());
  const [Error, setError] = useState("");
  const [Seasons, setSeasons] = useState([]);
  const [filterModel, setfilterModel] = useState({
    q: "",
    seasonId: 0,
    disponibilityId: 0,
    clientId: 0,
    page: 1,
    take: 300,
    // methodOfHearingAbout: 0,
    // isFirstTime: false,
    // generalReview: 0,
    // branchReview: 0,
    // guideReview: 0,
    // airlineReview: 0,
    // madinahHotelReview: 0,
    // madinahServiceReview: 0,
    // makkahHotelReview: 0,
    // makkahServiceReview: 0,
    // bestOf: 0,
    // isRecommended: false,
    // dateVole: "",
    // guideName: "",
    // hotelMakkah: "",
    // hotelMadinah: "",
    // isCouponUsed: false,
    // note: "",
  });

  const [surveyState, setsurveyState] = useRecoilState(surveyStateAtom);
  const [totalCount, settotalCount] = useState(0);
  const [Checked, setChecked] = useState(false);

  ////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////

  const CustomerType = (v) => {
    if (v == 1)
      return (
        <span
          style={{
            backgroundColor: "green",
            color: "white",
            textAlign: "center",
            padding: "2px 8px",
            borderRadius: 5,
            fontSize: 15,
          }}
        >
          Client
        </span>
      );
    else if (v == 2)
      return (
        <span
          style={{
            backgroundColor: "orange",
            color: "white",
            textAlign: "center",
            padding: "2px 8px",
            borderRadius: 5,
            fontSize: 15,
          }}
        >
          Guide
        </span>
      );
    else if (v == 3)
      return (
        <span
          style={{
            backgroundColor: "red",
            color: "white",
            textAlign: "center",
            padding: "2px 8px",
            borderRadius: 5,
            fontSize: 15,
          }}
        >
          Employé
        </span>
      );
  };

  const columns = [
    {
      value: "customer",
      render: (v) => {
        return (
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {
              <img
                src={Base_URL2 + "Uploads/CustomerPictures/" + v.picture}
                height={35}
                width={35}
                style={{
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              />
            }
          </p>
        );
      },
    },
    {
      name: "Nom",
      value: "customer",
      render: (v) => {
        return <p style={{ fontSize: 17, fontWeight: "bold" }}>{v.name}</p>;
      },
    },
    {
      name: "Type",
      value: "customer",
      render: (v) => {
        return <p style={{ fontSize: 17 }}>{CustomerType(v.customerType)}</p>;
      },
    },
    {
      name: "Date",
      value: "disponibility",
      render: (v) => {
        return (
          <p style={{ fontSize: 17 }}>
            {v ? moment(v.date).format("DD/MM/YYYY") : ""}
          </p>
        );
      },
    },
    {
      name: "Branche",
      value: "client",
      render: (v) => {
        return (
          <p
            //  style={branch_Color(v.name.substring(8))}
            style={{ fontSize: 17 }}
          >
            {v.name}
          </p>
        );
      },
    },

    {
      name: "Téléphone",
      value: "customer",
      render: (v) => {
        return (
          <p style={checkGuide(v)}>{v.phoneNumber ? v.phoneNumber : "--"}</p>
        );
      },
    },
    {
      name: "Survey",
      value: "surrveyState",
      render: (v) => {
        let x = surveyStatedata.find((el) => el.value == v);
        return (
          <div style={surveyStateColor(v)}>
            <span style={{ fontSize: 17 }}>{x ? x.label : ""}</span>
          </div>
        );
      },
    },
    {
      name: "Coupon",
      value: "customer",
      render: (v) => {
        return <p>{v.survey && v.survey.coupon ? <Checkbox checked /> : ""}</p>;
      },
    },
  ];
  const surveyStateColor = (v) => {
    if (v == 1) {
      return {
        backgroundColor: "#85ea2d",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        padding: 2,
      };
    } else if (v == 2) {
      return {
        backgroundColor: "grey",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        padding: 2,
      };
    } else if (v == 3) {
      return {
        backgroundColor: "black",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        padding: 2,
      };
    } else if (v == 4) {
      return {
        backgroundColor: "orange",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        padding: 2,
      };
    } else if (v == 5) {
      return {
        backgroundColor: "red",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        padding: 2,
      };
    }
  };
  ///// Grid Styles /////
  // const branch_Color = (v) => {
  //   if (["Sousse", "SFAX", "MONASTIR", "msaken", "TUNIS"].includes(v)) {
  //     return { color: "#009432", fontSize: 17, fontWeight: "bold" };
  //   } else if (["ARIANA", "JENDOUBA", "MEDNINE", "DJERBA"].includes(v)) {
  //     return { color: "#0652DD", fontSize: 17, fontWeight: "bold" };
  //   } else if (["MAHDIA", "KAIROUAN"].includes(v)) {
  //     return { color: "#EA2027", fontSize: 17, fontWeight: "bold" };
  //   } else if ("NABEUL" == v) {
  //     return { color: "#833471", fontSize: 17, fontWeight: "bold" };
  //   } else return { color: "#9900ff", fontSize: 17, fontWeight: "bold" };
  // };
  const checkGuide = (v) => {
    if (GuideNumbers.includes(v.phoneNumber)) {
      return {
        color: "red",
        fontSize: 17,
        width: 160,
        overflowX: "auto",
      };
    } else {
      return {
        fontSize: 17,
        width: 160,
        overflowX: "auto",
      };
    }
  };
  const getData = () => {
    createAPIEndpoint("/Order/UmrahCrm", filterModel)
      .fetch()
      .then((res) => {
        setdataMootmars(res.data.data);
        settotalCount(res.data.totalCount);
      })
      .catch((err) => console.log(err));
  };
  const reset = () => {
    setError("");
    setmodel(new CustomerModel());
    setSurveyModel(new surveyModel());
    setChecked(false);
    setstate((prev) => {
      return { ...prev, open: false, loading: false };
    });
    setsurveyState((prev) => {
      return { ...prev, open: false, loading: false };
    });
  };
  const getSeasons = () => {
    createAPIEndpoint("Season", { page: 1, take: 1000 })
      .fetch()
      .then((res) => {
        setSeasons(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const Update = () => {
    setstate((prev) => {
      return { ...prev, open: false, loading: true };
    });
    setsurveyState((prev) => {
      return { ...prev, open: false, loading: true };
    });
    let modelCopy = { ...model };

    delete modelCopy.disponibility;
    delete modelCopy.client;
    delete modelCopy.price;
    delete modelCopy.packageUmrah;

    if (!SurveyModel.id) {
      if (modelCopy.surrveyState == 1 || modelCopy.surrveyState == 4) {
        modelCopy = {
          ...modelCopy,
          surveyId: SurveyModel.Id,
          customer: {
            ...modelCopy.customer,
            survey: {
              ...SurveyModel,
              customerId: model.customer.id,
              clientId: model.client.id,
              dateVol: model.disponibility.date,
              guideName: model.guideName,
              hotelMakkah: model.price ? model.price.hotelMakkahName : "",
              hotelMadinah: model.price ? model.price.hotelMadinahName : "",
              createdBy: JSON.parse(localStorage.getItem("auth")).fullName,
              createdDate: new Date(),
            },
          },
        };
      }
      createAPIEndpoint("Order/Umrah")
        .update(modelCopy)
        .then(() => {
          if (Checked) {
            createAPIEndpoint("Message/SendCoupon")
              .fetchById(model.customer.id)
              .catch((err) => console.log(err));
          }
        })
        .then((res) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: ". تمّ التغير بنجاح ",
            showConfirmButton: false,
            timer: 1700,
          });

          getData();
          reset();
        })
        .catch((err) => console.log(err));
    } else {
      if (modelCopy.surrveyState == 1 || modelCopy.surrveyState == 4) {
        delete modelCopy.survey;
        delete modelCopy.surveyId;
        modelCopy.customer.surveyId = SurveyModel.id;
        // modelCopy.surveyId = SurveyModel.id;
        // modelCopy.survey = { ...SurveyModel };
        modelCopy.customer.survey = {
          ...SurveyModel,
          updatedBy: JSON.parse(localStorage.getItem("auth")).fullName,
          updatedDate: new Date(),
        };
        // console.log(modelCopy);
        // createAPIEndpoint("customer")
        //   .updateWithId(modelCopy.customer.id, modelCopy.customer)
        //   .then(() => getData())
        //   .catch((err) => console.log(err));
        createAPIEndpoint("Order/Umrah")
          .update(modelCopy)
          .then(() => {
            reset();
            getData();
          })
          .catch((err) => console.log(err));
        createAPIEndpoint("survey")
          .updateWithId(SurveyModel.id, {
            ...SurveyModel,
            updatedBy: JSON.parse(localStorage.getItem("auth")).fullName,
            updatedDate: new Date(),
          })
          .then(() => {
            if (Checked) {
              createAPIEndpoint("Message/SendCoupon")
                .fetchById(model.customer.id)
                .catch((err) => console.log(err));
            }
          })
          .then(() => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: ". تمّ التغير بنجاح ",
              showConfirmButton: false,
              timer: 1700,
            });
            reset();
            getData();
          })
          .catch((err) => console.log(err));
      } else {
        modelCopy = {
          ...modelCopy,
          customer: { ...modelCopy.customer, survey: null, surveyId: null },
        };
        createAPIEndpoint("Order/Umrah")
          .update(modelCopy)
          .then((res) => {
            createAPIEndpoint("survey")
              .delete(SurveyModel.id)
              .then((res) => {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: ". تمّ التغير بنجاح ",
                  showConfirmButton: false,
                  timer: 1700,
                });
                reset();
                getData();
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      }
    }
  };
  const getById = (id) => {
    createAPIEndpoint(`/Order/Umrah`)
      .fetchById(id)
      .then((res) => {
        setmodel(res.data);
        res.data.surrveyState == 1 || res.data.surrveyState == 4
          ? createAPIEndpoint("/Survey/GetByCustomerId")
              .fetchById(res.data.customerId)
              .then((resp) => {
                setSurveyModel(resp.data);
              })
              .catch((err) => console.log(err))
          : setSurveyModel(new surveyModel());
      })
      .catch((err) => console.log(err));
  };
  const getDates = () => {
    createAPIEndpoint("PackageUmrah/getForPrices", {
      q: "",
      disponibilityId: 0,
      page: 1,
      take: 200,
    })
      .fetch()
      .then((res) => {
        setdates(
          res.data.data
            .reduce(
              (accumulator, el) => accumulator.concat(el.disponibilities),
              []
            )
            .map((el) => ({
              label: el.airline
                ? moment(el.date).format("DD/MM/YYYY") +
                  " -- " +
                  el.airline.fullName
                : "",
              value: el.id,
            }))
        );
      })
      .catch((err) => console.log(err));
  };

  ////------------------------Clients--------------------///
  const getClients = () => {
    createAPIEndpoint("Client/getAll")
      .fetchAll()
      .then((res) => {
        setclients(
          res.data.map((el) => ({
            label: el.name,
            value: el.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };
  /////----------Get Guide----------/////
  const getGuidesNumbers = () => {
    createAPIEndpoint("Guide/getAll")
      .fetchAll()
      .then((res) => {
        let numbers = [];
        res.data
          .map((el) => {
            return el.phoneNumber;
          })
          .map((el) => (numbers = [...numbers, ...el.split("/")]));

        setGuideNumbers(numbers);
      })

      .catch((err) => console.log(err));
  };
  //////-----------pdf------------------//////

  useEffect(() => {
    getData();
    getClients();
    getDates();
    getGuidesNumbers();
    getSeasons();
  }, []);

  return (
    <React.Fragment>
      <div style={{ width: "99%", margin: "auto", marginTop: "12px" }}>
        <Filter
          search={getData}
          // advanced
          advancedComponent={
            <AdvancedSearch
              filterModel={filterModel}
              _setfilterModel={setfilterModel}
              advancedData={surveyTitles}
            />
          }
        >
          <Responsive l={3} xl={3} className="p-10">
            <label>Nom ou Téléphone: </label>
            <Input
              value={filterModel.q}
              placeholder="Rechercher"
              onChange={(q) => {
                setfilterModel((prev) => {
                  return { ...prev, q, page: 1 };
                });
              }}
            />
          </Responsive>
          <Responsive l={3} xl={3} className="p-10">
            <label> Date de Vol: </label>
            <InputPicker
              block
              placeholder="Rechercher"
              data={dates}
              onChange={(disponibilityId) => {
                setfilterModel((prev) => {
                  return { ...prev, disponibilityId, page: 1 };
                });
              }}
            />
          </Responsive>
          <Responsive l={3} xl={3} className="p-10">
            <label>Saison: </label>
            <InputPicker
              block
              data={[{ label: "Tout", value: 0 }].concat(
                Seasons.map((c) => {
                  return { label: c.designation, value: c.id };
                })
              )}
              value={filterModel.seasonId}
              placeholder="Rechercher"
              onChange={(seasonId) => {
                setfilterModel((prev) => {
                  return { ...prev, seasonId, page: 1 };
                });
              }}
            />
          </Responsive>
          <Responsive l={3} xl={3} className="p-10">
            <label> Agence: </label>
            <InputPicker
              block
              placeholder="Rechercher"
              data={clients}
              onChange={(clientId) => {
                setfilterModel((prev) => {
                  return { ...prev, clientId, page: 1 };
                });
              }}
            />
          </Responsive>
        </Filter>
      </div>

      <ExportAdd
        pdfDownload
        pdfTitle={"Note"}
        pdfComponent={
          <ComponentToPrint
            data={dataMootmars
              .filter((el) => el.customer.survey)
              .filter((el) => el.customer.survey.note)}
          />
        }
        ActionOnClose={reset}
        save={Update}
        saveSurvey={Update}
        size="full"
        noExport
        noimportExcel
        AddComponent={
          <AddEdit error={Error} model={model} _setmodel={setmodel} />
        }
        AddsurveyComponent={
          <AddSurvey
            selectedMootamar={model}
            setselectedMootmar={setmodel}
            model={SurveyModel}
            _setmodel={setSurveyModel}
            Checked={Checked}
            setChecked={setChecked}
          />
        }
      />
      <div style={{ width: "99%", margin: "auto" }}>
        <Grid
          actionKey="id"
          checkAction={(id) => {
            getById(id);
            setstate((prev) => {
              return { ...prev, open: true };
            });
          }}
          addServeyAction={(id) => {
            getById(id);
            setsurveyState((prev) => {
              return { ...prev, open: true };
            });
          }}
          columns={columns}
          noAdvancedActions={true}
          rows={dataMootmars}
        />
        <div style={{ padding: 20, background: "#fff" }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={totalCount}
            limitOptions={[25, 50, 100, 300]}
            limit={filterModel.take}
            activePage={filterModel.page}
            onChangePage={(page) => {
              setfilterModel((prev) => {
                return { ...prev, page };
              });
              createAPIEndpoint("/Order/Umrah", {
                ...filterModel,
                page,
              })
                .fetch()
                .then((res) => {
                  setdataMootmars(res.data.data);
                  settotalCount(res.data.totalCount);
                })
                .catch((err) => console.log(err));
            }}
            onChangeLimit={(take) => {
              setfilterModel((prev) => {
                return { ...prev, take, page: 1 };
              });
              createAPIEndpoint("/Order/Umrah", {
                ...filterModel,
                take,
                page: 1,
              })
                .fetch()
                .then((res) => {
                  setdataMootmars(res.data.data);
                  settotalCount(res.data.totalCount);
                })
                .catch((err) => console.log(err));
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Customer;
