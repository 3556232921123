import DashboardIcon from "@rsuite/icons/Dashboard";
import EmailFillIcon from "@rsuite/icons/EmailFill";
import GearIcon from "@rsuite/icons/Gear";
import MemberIcon from "@rsuite/icons/Member";
import PhoneIcon from "@rsuite/icons/Phone";
import SendIcon from "@rsuite/icons/Send";
import React, { useEffect, useState } from "react";
import { BiSolidUserCircle } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import { Link, Route, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import { RiMenuFill } from "react-icons/ri";
import TaskIcon from "@rsuite/icons/Task";
import TextImageIcon from "@rsuite/icons/TextImage";
import { MdOutlineLogout } from "react-icons/md";

import AppSelectIcon from "@rsuite/icons/AppSelect";

import {
  Button,
  Container,
  Content,
  Dropdown,
  Header,
  Nav,
  Sidebar,
  Sidenav,
} from "rsuite";
import { isLogged } from "./Atoms/auth.atom";
import Login from "./Screens/Auth/login";
import Customer from "./Screens/Customer";

import "./App.scss";
import Dashboard from "./Screens/Dashboard";
import Guide from "./Screens/Guide";
import Messages from "./Screens/Messages";
import SendMessages from "./Screens/SendMessages";

import SurveyMadinah from "./Screens/SurveyMadinah";
import SurveyMakkah from "./Screens/SurveyMakkah";
import Users from "./Screens/Users";

import Flayers from "./Screens/Flayers";
import OldCustomer from "./Screens/OldCustomer";
import Clients from "./Screens/Clients";

function App() {
  const [expand, setExpand] = useState(false);
  const [logged, setlogged] = useRecoilState(isLogged);
  const [active, setactive] = useState("1");
  const [pageTitle, setpageTitle] = useState("Dashboard");
  const [isMobile, setisMobile] = useState(false);

  /////--------

  ////////////////////////
  const AuthGard = (Screen) => (logged ? Screen : <Login />);
  useEffect(() => {
    let log = true;
    if (
      !localStorage.getItem("auth") ||
      !JSON.parse(localStorage.getItem("auth")).token
    ) {
      log = false;
    } else {
      setlogged(log);
      if (
        window.location.pathname == "/" ||
        window.location.pathname.substring(1) == "Dashboard"
      ) {
        setpageTitle("Dashboard");
        setactive(1);
      } else if (window.location.pathname.substring(1) == "Customer") {
        setpageTitle(" Sondage Umrah");
        setactive(2);
      } else if (window.location.pathname.substring(1) == "SurveyMadinah") {
        setpageTitle(" SurveyMadinah");
        setactive(3);
      } else if (window.location.pathname.substring(1) == "SurveyMakkah") {
        setpageTitle(" SurveyMakkah");
        setactive(4);
      } else if (window.location.pathname.substring(1) == "Guide") {
        setpageTitle("Guide");
        setactive(5);
      } else if (window.location.pathname.substring(1) == "Messages") {
        setpageTitle("Messages");
        setactive(6);
      } else if (window.location.pathname.substring(1) == "SendMessages") {
        setpageTitle("Messages");
        setactive(7);
      } else if (window.location.pathname.substring(1) == "flayers") {
        setpageTitle("Flayers");
        setactive(8);
      } else if (window.location.pathname.substring(1) == "Users") {
        setpageTitle("Utilisateurs");
        setactive(9);
      }
    }
  }, [logged]);

  // useEffect(() => getGuides(), []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1300) setisMobile(true);
      else setisMobile(false);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => console.log(isMobile), [isMobile]);

  return (
    <div className="show-fake-browser sidebar-page">
      <Container>
        {logged && !isMobile && (
          <Sidebar
            style={{ display: "flex", flexDirection: "column" }}
            width={260}
            collapsible
          >
            <Sidenav.Header>
              <div style={headerStyles}>
                <img
                  src="./logo.png"
                  height={40}
                  width={40}
                  style={{ marginLeft: 10 }}
                />
                <span style={{ fontSize: 21, fontWeight: "bold" }}>
                  Kounouz CRM
                </span>
              </div>
            </Sidenav.Header>
            <Sidenav defaultOpenKeys={["1"]} appearance="subtle">
              <Sidenav.Body>
                <Nav style={{ paddingLeft: 3, paddingRight: 3 }}>
                  <Nav.Item
                    eventKey="1"
                    active={active == "1"}
                    icon={<DashboardIcon />}
                    style={
                      active == 1
                        ? {
                            border: "solid 1px",
                            borderRadius: 10,
                            color: "white",
                            background: "rgba(25,42,86,0.9)",
                            width: 250,
                          }
                        : {}
                    }
                    onClick={() => {
                      setpageTitle("Dashboard");
                      setactive(1);
                    }}
                  >
                    <Link
                      style={
                        active != 1
                          ? { color: "black" }
                          : { color: "white", fontWeight: "bold" }
                      }
                      to="/"
                    >
                      Dashboard
                    </Link>
                  </Nav.Item>

                  {/* <Nav.Item
                    eventKey="2"
                    active={active == "2"}
                    icon={<GroupIcon />}
                    onClick={() => {
                      setpageTitle("Ajouter Mootmar ");
                      setactive(2);
                    }}
                  >
                    <Link
                      style={active != 2 ? { color: "black" } : {}}
                      to="/Add_Customer"
                    >
                      Ajouter Mootmar
                    </Link>
                  </Nav.Item> */}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "CentralUser" ||
                    JSON.parse(localStorage.getItem("auth")).role ==
                      "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="2"
                      active={active == "2"}
                      style={
                        active == 2
                          ? {
                              border: "solid 1px",
                              borderRadius: 10,
                              color: "white",
                              background: "rgba(25,42,86,0.9)",
                              width: 250,
                            }
                          : {}
                      }
                      icon={<PhoneIcon />}
                      onClick={() => {
                        setpageTitle(" Sondage Umrah ");
                        setactive(2);
                      }}
                    >
                      <Link
                        style={
                          active != 2
                            ? { color: "black" }
                            : { color: "white", fontWeight: "bold" }
                        }
                        to="/Customer"
                      >
                        Sondage Umrah
                      </Link>
                    </Nav.Item>
                  )}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "MadinahUser" ||
                    JSON.parse(localStorage.getItem("auth")).role ==
                      "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="3"
                      active={active == "3"}
                      icon={<PhoneIcon />}
                      style={
                        active == 3
                          ? {
                              border: "solid 1px",
                              borderRadius: 10,
                              color: "white",
                              background: "rgba(25,42,86,0.9)",
                              width: 250,
                            }
                          : {}
                      }
                      onClick={() => {
                        setpageTitle(" Sondage Madinah ");
                        setactive(3);
                      }}
                    >
                      <Link
                        style={
                          active != 3
                            ? { color: "black" }
                            : { color: "white", fontWeight: "bold" }
                        }
                        to="/SurveyMadinah"
                      >
                        Sondage Madinah
                      </Link>
                    </Nav.Item>
                  )}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "MakkahUser" ||
                    JSON.parse(localStorage.getItem("auth")).role ==
                      "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="4"
                      active={active == "4"}
                      style={
                        active == 4
                          ? {
                              border: "solid 1px",
                              borderRadius: 10,
                              color: "white",
                              background: "rgba(25,42,86,0.9)",
                              width: 250,
                            }
                          : {}
                      }
                      icon={<PhoneIcon />}
                      onClick={() => {
                        setpageTitle(" Sondage Makkah ");
                        setactive(4);
                      }}
                    >
                      <Link
                        style={
                          active != 4
                            ? { color: "black" }
                            : { color: "white", fontWeight: "bold" }
                        }
                        to="/SurveyMakkah"
                      >
                        Sondage Makkah
                      </Link>
                    </Nav.Item>
                  )}

                  {/* <Nav.Item
                    eventKey="14"
                    active={active == "14"}
                    icon={<PhoneIcon />}
                    onClick={() => {
                      setpageTitle("Sondage Voyage Organisé");
                      setactive(14);
                    }}
                  >
                    <Link
                      style={active != 14 ? { color: "black" } : {}}
                      to="/SurveyVO"
                    >
                      Sondage V.O
                    </Link>
                  </Nav.Item> */}
                  {/* <Nav.Item
                    eventKey="15"
                    active={active == "15"}
                    icon={<PhoneIcon />}
                    onClick={() => {
                      setpageTitle("Tickets");
                      setactive(15);
                    }}
                  >
                    <Link
                      style={active != 15 ? { color: "black" } : {color: "white" }}
                      to="/Tickets"
                    >
                      Billeterie
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="16"
                    active={active == "16"}
                    icon={<PhoneIcon />}
                    onClick={() => {
                      setpageTitle("Hotels ");
                      setactive(16);
                    }}
                  >
                    <Link
                      style={active != 16 ? { color: "black" } : {color: "white" }}
                      to="/Hotels"
                    >
                      Hôtels
                    </Link>
                  </Nav.Item> */}

                  <Nav.Item
                    eventKey="5"
                    active={active == "5"}
                    style={
                      active == 5
                        ? {
                            border: "solid 1px",
                            borderRadius: 10,
                            color: "white",
                            background: "rgba(25,42,86,0.9)",
                            width: 250,
                          }
                        : {}
                    }
                    icon={<MemberIcon />}
                    onClick={() => {
                      setpageTitle("Guide");
                      setactive(5);
                    }}
                  >
                    <Link
                      style={
                        active != 5
                          ? { color: "black" }
                          : { color: "white", fontWeight: "bold" }
                      }
                      to="/Guide"
                    >
                      Guide
                    </Link>
                  </Nav.Item>

                  {JSON.parse(localStorage.getItem("auth")) &&
                    (JSON.parse(localStorage.getItem("auth")).role ==
                      "CentralUser" ||
                      JSON.parse(localStorage.getItem("auth")).role ==
                        "AdminCrm" ||
                      JSON.parse(localStorage.getItem("auth")).fullName ==
                        "Admin Super") && (
                      <Nav.Item
                        eventKey="10"
                        active={active == "10"}
                        style={
                          active == 10
                            ? {
                                border: "solid 1px",
                                borderRadius: 10,
                                color: "white",
                                background: "rgba(25,42,86,0.9)",
                                width: 250,
                              }
                            : {}
                        }
                        icon={<AppSelectIcon />}
                        onClick={() => {
                          setpageTitle("Agences");
                          setactive(10);
                        }}
                      >
                        <Link
                          style={
                            active != 10
                              ? { color: "black" }
                              : { color: "white", fontWeight: "bold" }
                          }
                          to="/Clients"
                        >
                          Agences
                        </Link>
                      </Nav.Item>
                    )}

                  {JSON.parse(localStorage.getItem("auth")) &&
                    (JSON.parse(localStorage.getItem("auth")).role ==
                      "CentralUser" ||
                      JSON.parse(localStorage.getItem("auth")).role ==
                        "AdminCrm" ||
                      JSON.parse(localStorage.getItem("auth")).fullName ==
                        "Admin Super") && (
                      <Nav.Item
                        eventKey="6"
                        active={active == "6"}
                        style={
                          active == 6
                            ? {
                                border: "solid 1px",
                                borderRadius: 10,
                                color: "white",
                                background: "rgba(25,42,86,0.9)",
                                width: 250,
                              }
                            : {}
                        }
                        icon={<EmailFillIcon />}
                        onClick={() => {
                          setpageTitle("Messages");
                          setactive(6);
                        }}
                      >
                        <Link
                          style={
                            active != 6
                              ? { color: "black" }
                              : { color: "white", fontWeight: "bold" }
                          }
                          to="/Messages"
                        >
                          Messages
                        </Link>
                      </Nav.Item>
                    )}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "CentralUser" ||
                    JSON.parse(localStorage.getItem("auth")).role ==
                      "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="7"
                      active={active == "7"}
                      style={
                        active == 7
                          ? {
                              border: "solid 1px",
                              borderRadius: 10,
                              color: "white",
                              background: "rgba(25,42,86,0.9)",
                              width: 250,
                            }
                          : {}
                      }
                      icon={<SendIcon />}
                      onClick={() => {
                        setpageTitle("Envoyer Messages");
                        setactive(7);
                      }}
                    >
                      <Link
                        style={
                          active != 7
                            ? { color: "black" }
                            : { color: "white", fontWeight: "bold" }
                        }
                        to="/SendMessages"
                      >
                        Envoyer Messages
                      </Link>
                    </Nav.Item>
                  )}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="8"
                      active={active == "8"}
                      style={
                        active == 8
                          ? {
                              border: "solid 1px",
                              borderRadius: 10,
                              color: "white",
                              background: "rgba(25,42,86,0.9)",
                              width: 250,
                            }
                          : {}
                      }
                      icon={<TextImageIcon />}
                      onClick={() => {
                        setpageTitle("Flayers");
                        setactive(8);
                      }}
                    >
                      <Link
                        style={
                          active != 8
                            ? { color: "black" }
                            : { color: "white", fontWeight: "bold" }
                        }
                        to="/flayers"
                      >
                        Flayers
                      </Link>
                    </Nav.Item>
                  )}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="9"
                      active={active == "9"}
                      style={
                        active == 9
                          ? {
                              border: "solid 1px",
                              borderRadius: 10,
                              color: "white",
                              background: "rgba(25,42,86,0.9)",
                              width: 250,
                            }
                          : {}
                      }
                      icon={<MemberIcon />}
                      onClick={() => {
                        setpageTitle("Utilisateurs");
                        setactive(9);
                      }}
                    >
                      <Link
                        style={
                          active != 9
                            ? { color: "black" }
                            : { color: "white", fontWeight: "bold" }
                        }
                        to="/Users"
                      >
                        Utilisateurs
                      </Link>
                    </Nav.Item>
                  )}
                </Nav>
              </Sidenav.Body>
            </Sidenav>
          </Sidebar>
        )}
        {/*//////////////////------------------- Mobile Menu-------------------/////////////// */}
        {logged && isMobile && expand && (
          <div
            style={{
              position: "absolute",
              top: 0,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              background: "white",
              height: "100%",
              zIndex: 100,
            }}
          >
            <Sidebar
              style={{ display: "flex", flexDirection: "column" }}
              width={"100%"}
              collapsible={false}
            >
              <Sidenav.Header>
                <div style={headerStyles}>
                  <div>
                    <img
                      src="./logo.png"
                      height={40}
                      width={40}
                      style={{ marginLeft: 10 }}
                    />
                    <span style={{ fontSize: 21 }}>Kounouz Travel CRM</span>
                  </div>
                  {isMobile && (
                    <Button
                      className="menu_btn"
                      onClick={() => setExpand((prev) => !prev)}
                      appearance="ghost"
                      style={{ border: "white 1px solid" }}
                    >
                      <FiMenu color="white" />
                    </Button>
                  )}
                </div>
              </Sidenav.Header>
              <Sidenav
                expanded={expand}
                defaultOpenKeys={["1"]}
                appearance="subtle"
              >
                <Sidenav.Body>
                  <Nav>
                    <Nav.Item
                      eventKey="1"
                      active={active == "1"}
                      icon={<DashboardIcon />}
                      style={
                        active == 1
                          ? {
                              border: "solid 1px",
                              borderRadius: 10,
                              color: "white",
                              background: "rgba(25,42,86,0.9)",
                            }
                          : {}
                      }
                      onClick={() => {
                        setpageTitle("Dashboard");
                        setactive(1);
                        setExpand(false);
                      }}
                    >
                      <Link
                        style={
                          active != 1
                            ? { color: "black" }
                            : { color: "white", fontWeight: "bold" }
                        }
                        to="/"
                      >
                        Dashboard
                      </Link>
                    </Nav.Item>

                    {/* <Nav.Item
                    eventKey="2"
                    active={active == "2"}
                    icon={<GroupIcon />}
                    onClick={() => {
                      setpageTitle("Ajouter Mootmar ");
                      setactive(2);
                    }}
                  >
                    <Link
                      style={active != 2 ? { color: "black" } : {}}
                      to="/Add_Customer"
                    >
                      Ajouter Mootmar
                    </Link>
                  </Nav.Item> */}
                    {(JSON.parse(localStorage.getItem("auth")).role ==
                      "CentralUser" ||
                      JSON.parse(localStorage.getItem("auth")).role ==
                        "AdminCrm" ||
                      JSON.parse(localStorage.getItem("auth")).fullName ==
                        "Admin Super") && (
                      <Nav.Item
                        eventKey="2"
                        active={active == "2"}
                        style={
                          active == 2
                            ? {
                                border: "solid 1px",
                                borderRadius: 10,
                                color: "white",
                                background: "rgba(25,42,86,0.9)",
                              }
                            : {}
                        }
                        icon={<PhoneIcon />}
                        onClick={() => {
                          setpageTitle(" Sondage Umrah ");
                          setactive(2);
                          setExpand(false);
                        }}
                      >
                        <Link
                          style={
                            active != 2
                              ? { color: "black" }
                              : { color: "white", fontWeight: "bold" }
                          }
                          to="/Customer"
                        >
                          Sondage Umrah
                        </Link>
                      </Nav.Item>
                    )}
                    {(JSON.parse(localStorage.getItem("auth")).role ==
                      "MadinahUser" ||
                      JSON.parse(localStorage.getItem("auth")).role ==
                        "AdminCrm" ||
                      JSON.parse(localStorage.getItem("auth")).fullName ==
                        "Admin Super") && (
                      <Nav.Item
                        eventKey="3"
                        active={active == "3"}
                        icon={<PhoneIcon />}
                        style={
                          active == 3
                            ? {
                                border: "solid 1px",
                                borderRadius: 10,
                                color: "white",
                                background: "rgba(25,42,86,0.9)",
                              }
                            : {}
                        }
                        onClick={() => {
                          setpageTitle(" Sondage Madinah ");
                          setactive(3);
                          setExpand(false);
                        }}
                      >
                        <Link
                          style={
                            active != 3
                              ? { color: "black" }
                              : { color: "white", fontWeight: "bold" }
                          }
                          to="/SurveyMadinah"
                        >
                          Sondage Madinah
                        </Link>
                      </Nav.Item>
                    )}
                    {(JSON.parse(localStorage.getItem("auth")).role ==
                      "MakkahUser" ||
                      JSON.parse(localStorage.getItem("auth")).role ==
                        "AdminCrm" ||
                      JSON.parse(localStorage.getItem("auth")).fullName ==
                        "Admin Super") && (
                      <Nav.Item
                        eventKey="4"
                        active={active == "4"}
                        style={
                          active == 4
                            ? {
                                border: "solid 1px",
                                borderRadius: 10,
                                color: "white",
                                background: "rgba(25,42,86,0.9)",
                              }
                            : {}
                        }
                        icon={<PhoneIcon />}
                        onClick={() => {
                          setpageTitle(" Sondage Makkah ");
                          setactive(4);
                          setExpand(false);
                        }}
                      >
                        <Link
                          style={
                            active != 4
                              ? { color: "black" }
                              : { color: "white", fontWeight: "bold" }
                          }
                          to="/SurveyMakkah"
                        >
                          Sondage Makkah
                        </Link>
                      </Nav.Item>
                    )}

                    {/* <Nav.Item
                    eventKey="14"
                    active={active == "14"}
                    icon={<PhoneIcon />}
                    onClick={() => {
                      setpageTitle("Sondage Voyage Organisé");
                      setactive(14);
                    }}
                  >
                    <Link
                      style={active != 14 ? { color: "black" } : {}}
                      to="/SurveyVO"
                    >
                      Sondage V.O
                    </Link>
                  </Nav.Item> */}
                    {/* <Nav.Item
                    eventKey="15"
                    active={active == "15"}
                    icon={<PhoneIcon />}
                    onClick={() => {
                      setpageTitle("Tickets");
                      setactive(15);
                    }}
                  >
                    <Link
                      style={active != 15 ? { color: "black" } : {}}
                      to="/Tickets"
                    >
                      Billeterie
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="16"
                    active={active == "16"}
                    icon={<PhoneIcon />}
                    onClick={() => {
                      setpageTitle("Hotels ");
                      setactive(16);
                    }}
                  >
                    <Link
                      style={active != 16 ? { color: "black" } : {}}
                      to="/Hotels"
                    >
                      Hôtels
                    </Link>
                  </Nav.Item> */}

                    <Nav.Item
                      eventKey="5"
                      active={active == "5"}
                      style={
                        active == 5
                          ? {
                              border: "solid 1px",
                              borderRadius: 10,
                              color: "white",
                              background: "rgba(25,42,85,0.9)",
                            }
                          : {}
                      }
                      icon={<MemberIcon />}
                      onClick={() => {
                        setpageTitle("Guide");
                        setactive(5);
                        setExpand(false);
                      }}
                    >
                      <Link
                        style={
                          active != 5
                            ? { color: "black" }
                            : { color: "white", fontWeight: "bold" }
                        }
                        to="/Guide"
                      >
                        Guide
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      eventKey="10"
                      active={active == "10"}
                      style={
                        active == 10
                          ? {
                              border: "solid 1px",
                              borderRadius: 10,
                              color: "white",
                              background: "rgba(25,42,85,0.9)",
                            }
                          : {}
                      }
                      icon={<AppSelectIcon />}
                      onClick={() => {
                        setpageTitle("Agences");
                        setactive(10);
                        setExpand(false);
                      }}
                    >
                      <Link
                        style={
                          active != 10
                            ? { color: "black" }
                            : { color: "white", fontWeight: "bold" }
                        }
                        to="/clients"
                      >
                        Agences
                      </Link>
                    </Nav.Item>
                    {JSON.parse(localStorage.getItem("auth")) &&
                      (JSON.parse(localStorage.getItem("auth")).role ==
                        "CentralUser" ||
                        JSON.parse(localStorage.getItem("auth")).role ==
                          "AdminCrm" ||
                        JSON.parse(localStorage.getItem("auth")).fullName ==
                          "Admin Super") && (
                        <Nav.Item
                          eventKey="6"
                          active={active == "6"}
                          style={
                            active == 6
                              ? {
                                  border: "solid 1px",
                                  borderRadius: 10,
                                  color: "white",
                                  background: "rgba(25,42,86,0.9)",
                                }
                              : {}
                          }
                          icon={<EmailFillIcon />}
                          onClick={() => {
                            setpageTitle("Messages");
                            setactive(6);
                            setExpand(false);
                          }}
                        >
                          <Link
                            style={
                              active != 6
                                ? { color: "black" }
                                : { color: "white", fontWeight: "bold" }
                            }
                            to="/Messages"
                          >
                            Messages
                          </Link>
                        </Nav.Item>
                      )}
                    {(JSON.parse(localStorage.getItem("auth")).role ==
                      "CentralUser" ||
                      JSON.parse(localStorage.getItem("auth")).role ==
                        "AdminCrm" ||
                      JSON.parse(localStorage.getItem("auth")).fullName ==
                        "Admin Super") && (
                      <Nav.Item
                        eventKey="7"
                        active={active == "7"}
                        style={
                          active == 7
                            ? {
                                border: "solid 1px",
                                borderRadius: 10,
                                color: "white",
                                background: "rgba(25,42,86,0.9)",
                              }
                            : {}
                        }
                        icon={<SendIcon />}
                        onClick={() => {
                          setpageTitle("Envoyer Messages");
                          setactive(7);
                          setExpand(false);
                        }}
                      >
                        <Link
                          style={
                            active != 7
                              ? { color: "black" }
                              : { color: "white", fontWeight: "bold" }
                          }
                          to="/SendMessages"
                        >
                          Envoyer Messages
                        </Link>
                      </Nav.Item>
                    )}
                    {(JSON.parse(localStorage.getItem("auth")).role ==
                      "AdminCrm" ||
                      JSON.parse(localStorage.getItem("auth")).fullName ==
                        "Admin Super") && (
                      <Nav.Item
                        eventKey="8"
                        active={active == "8"}
                        style={
                          active == 8
                            ? {
                                border: "solid 1px",
                                borderRadius: 10,
                                color: "white",
                                background: "rgba(25,42,86,0.9)",
                              }
                            : {}
                        }
                        icon={<TextImageIcon />}
                        onClick={() => {
                          setpageTitle("Flayers");
                          setactive(8);
                        }}
                      >
                        <Link
                          style={
                            active != 8
                              ? { color: "black" }
                              : { color: "white", fontWeight: "bold" }
                          }
                          to="/flayers"
                        >
                          Flayers
                        </Link>
                      </Nav.Item>
                    )}
                    {(JSON.parse(localStorage.getItem("auth")).role ==
                      "AdminCrm" ||
                      JSON.parse(localStorage.getItem("auth")).fullName ==
                        "Admin Super") && (
                      <Nav.Item
                        eventKey="9"
                        active={active == "9"}
                        style={
                          active == 9
                            ? {
                                border: "solid 1px",
                                borderRadius: 10,
                                color: "white",
                                background: "rgba(25,42,86,0.9)",
                              }
                            : {}
                        }
                        icon={<MemberIcon />}
                        onClick={() => {
                          setpageTitle("Utilisateurs");
                          setactive(9);
                          setExpand(false);
                        }}
                      >
                        <Link
                          style={
                            active != 9
                              ? { color: "black" }
                              : { color: "white", fontWeight: "bold" }
                          }
                          to="/Users"
                        >
                          Utilisateurs
                        </Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </Sidenav.Body>
              </Sidenav>
            </Sidebar>
          </div>
        )}

        <Container style={{ minHeight: "100vh" }}>
          {logged && (
            <Header
              style={{
                height: 56,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "rgba(25,42,86,0.9)",
                color: "white",
              }}
            >
              <div
                style={{
                  marginLeft: 10,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                <h4>{pageTitle}</h4>
              </div>
              <div
                style={{
                  display: "inline-flex",
                  marginRight: 15,
                }}
              >
                <Nav>
                  {isMobile && (
                    <Button
                      className="menu_btn"
                      onClick={() => setExpand((prev) => !prev)}
                    >
                      <RiMenuFill />
                    </Button>
                  )}

                  <Dropdown
                    placement="bottomEnd"
                    trigger="click"
                    icon={<GearIcon size="3em" color="white" />}

                    // renderTitle={(children) => {
                    //   return <GearIcon style={iconStyles} />;
                    // }}
                  >
                    <Dropdown.Item>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            // background: "red",
                            borderRadius: "50%",
                            marginRight: 5,
                          }}
                        >
                          <BiSolidUserCircle size={20} />
                        </div>
                        <span>
                          {JSON.parse(localStorage.getItem("auth")).fullName}
                        </span>
                      </div>
                    </Dropdown.Item>

                    {/* <Dropdown.Item>Help</Dropdown.Item>
                    <Dropdown.Item>Settings</Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => {
                        localStorage.removeItem("auth");
                        setlogged(false);
                      }}
                      icon={<MdOutlineLogout size={20} />}
                      style={{ textAlign: "center" }}
                    >
                      Sign out
                    </Dropdown.Item>
                  </Dropdown>
                </Nav>
              </div>
            </Header>
          )}
          <Content style={{ background: "rgb(238,238,238)" }}>
            {/* <Button appearance="primary" onClick={() => FixGuide()}>
              Test
            </Button> */}
            <Routes>
              <Route path="*" element={AuthGard(<Dashboard />)} />
              <Route path="/" element={AuthGard(<Dashboard />)} />
              <Route path="/Add_Customer" element={AuthGard(<OldCustomer />)} />
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "CentralUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route path="/Customer" element={AuthGard(<Customer />)} />
                )}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "MadinahUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route
                    path="/SurveyMadinah"
                    element={AuthGard(<SurveyMadinah />)}
                  />
                )}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "MakkahUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route
                    path="/SurveyMakkah"
                    element={AuthGard(<SurveyMakkah />)}
                  />
                )}

              {/* <Route path="/SurveyVO" element={AuthGard(<SurveyVo />)} />
              <Route path="/Tickets" element={AuthGard(<Tickets />)} />
              <Route path="/Hotels" element={AuthGard(<Hotels />)} /> */}
              <Route path="/Guide" element={AuthGard(<Guide />)} />
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "CentralUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route
                    path="/SendMessages"
                    element={AuthGard(<SendMessages />)}
                  />
                )}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "CentralUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route path="/Messages" element={AuthGard(<Messages />)} />
                )}
              {/* <Route
                path="/verifyCoupon"
                element={AuthGard(<VerifyCoupon />)}
              /> */}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route path="/Users" element={AuthGard(<Users />)} />
                )}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route path="/flayers" element={AuthGard(<Flayers />)} />
                )}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "CentralUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route path="/Clients" element={AuthGard(<Clients />)} />
                )}
            </Routes>
          </Content>
        </Container>
      </Container>
    </div>
  );
}

export default App;

const headerStyles = {
  display: "flex",
  alignItems: "center",
  padding: 5,
  fontSize: 16,
  height: 56,
  background: "#192a56",
  color: " #fff",
  whiteSpace: "nowrap",
  overflow: "hidden",
  // justifyContent: "space-between",
  gap: 10,
};
