import { atom } from "recoil";
export const userCredentials = atom({
  key: "userCredentials", // unique ID (with respect to other atoms/selectors)
  default: {
    ccaId: "",
    checkoutId: "",
    fullName: " ",
    role: "",
    success: false,
    token: "",
    userName: "",
  }, // default value (aka initial value)
});
