import React, { useEffect, useState } from "react";
import Responsive from "../../Components/Responsive";
import { Input, InputPicker } from "rsuite";
import { createAPIEndpoint } from "../../API/authenticated.request";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import { IconButton } from "rsuite";

const AddEdit = ({ model, setmodel }) => {
  const [roles, setroles] = useState([]);
  const [add, setadd] = useState(false);
  const getRoles = () => {
    createAPIEndpoint("Role/crmRoles")
      .fetchAll()
      .then((res) =>
        setroles(
          res.data.map((role, index) => ({
            label: role.name,
            value: role.name,
          }))
        )
      )
      .catch((err) => console.log(err));
  };
  useEffect(() => getRoles(), []);

  return (
    <div>
      <Responsive className="p-10" m={6} l={6} xl={6}>
        <label htmlFor="">Nom:</label>
        <Input
          value={model.firstName}
          onChange={(firstName) => setmodel((prev) => ({ ...prev, firstName }))}
        />
      </Responsive>
      <Responsive className="p-10" m={6} l={6} xl={6}>
        <label htmlFor="">Prénom:</label>
        <Input
          value={model.lastName}
          onChange={(lastName) => setmodel((prev) => ({ ...prev, lastName }))}
        />
      </Responsive>
      <Responsive className="p-10" m={4} l={4} xl={4}>
        <label htmlFor="">userName</label>
        <Input
          value={model.userName}
          onChange={(userName) => setmodel((prev) => ({ ...prev, userName }))}
        />
      </Responsive>
      <Responsive className="p-10" m={4} l={4} xl={4}>
        <label htmlFor="">email:</label>
        <Input
          value={model.email}
          onChange={(email) => setmodel((prev) => ({ ...prev, email }))}
        />
      </Responsive>
      <Responsive className="p-10" m={4} l={4} xl={4}>
        <label htmlFor="">Password:</label>
        <Input
          type="password"
          value={model.password}
          onChange={(password) => setmodel((prev) => ({ ...prev, password }))}
        />
      </Responsive>
      <Responsive className="p-10" m={4} l={4} xl={4}>
        <label htmlFor="">Téléphone:</label>
        <Input
          value={model.phoneNumber}
          onChange={(phoneNumber) =>
            setmodel((prev) => ({ ...prev, phoneNumber }))
          }
        />
      </Responsive>
      <Responsive className="p-10" m={4} l={4} xl={4}>
        <label htmlFor="">Role:</label>
        <Responsive>
          <InputPicker
            data={roles}
            // block
            style={{ marginRight: 5 }}
            value={model.position}
            onChange={(position) => setmodel((prev) => ({ ...prev, position }))}
          />
          <IconButton
            icon={<PlusRoundIcon color="white" />}
            appearance="primary"
            style={{ background: "#2c3e50" }}
            onClick={() => setadd(!add)}
          />
        </Responsive>
      </Responsive>
      {add && (
        <Responsive className="p-10">
          <Input
            value={model.position}
            onChange={(position) => setmodel((prev) => ({ ...prev, position }))}
          />
        </Responsive>
      )}
    </div>
  );
};

export default AddEdit;
