import React, { useEffect, useState } from "react";
import { createAPIEndpoint } from "../../API/authenticated.request";
import Filter from "../../Components/Common/Filter";
import Responsive from "../../Components/Responsive";
import { Input, Pagination } from "rsuite";
import ExportAdd from "../../Components/Common/ExportAdd";
import AddEdit from "./AddEdit";
import Grid from "../../Components/Grid";
import { useRecoilState } from "recoil";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import Swal from "sweetalert2";

const Clients = () => {
  const [DefaultClients, setDefaultClients] = useState([]);
  const [clients, setclients] = useState([]);
  const [filterModel, setfilterModel] = useState({
    q: "",
    phoneNumber: "",
  });
  const [model, setmodel] = useState({});
  const [totalCount, settotalCount] = useState(0);
  const [state, setstate] = useRecoilState(exportAddAtom);
  const getClient = () => {
    createAPIEndpoint("Client/getAll")
      .fetchAll()
      .then((res) => {
        settotalCount(res.data.length);
        setclients(res.data);
        setDefaultClients(res.data);
      })
      .catch((err) => console.log(err));
  };
  const getById = (id) => {
    createAPIEndpoint("Client")
      .fetchById(id)
      .then((res) => setmodel(res.data))
      .catch((err) => console.log(err));
  };
  const save = () => {
    createAPIEndpoint("Client")
      .updateWithId(model.id, model)
      .then(() => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
      })
      .catch((err) => console.log(err));
  };
  const reset = () => {
    setmodel({});
    setstate((prev) => ({ ...prev, open: false }));
  };
  const filter = () => {
    if (filterModel.q && filterModel.phoneNumber) {
      setclients(
        DefaultClients.filter((el) =>
          el.phones
            ? el.name.trim().includes(filterModel.q) &&
              el.phones.trim().includes(filterModel.phoneNumber)
            : ""
        )
      );
    } else if (filterModel.phoneNumber) {
      setclients(
        DefaultClients.filter((el) =>
          el.phones ? el.phones.trim().includes(filterModel.phoneNumber) : ""
        )
      );
    } else if (filterModel.q) {
      setclients(
        DefaultClients.filter((el) =>
          el.name.toUpperCase().includes(filterModel.q.toUpperCase())
        )
      );
    } else getClient();
  };
  useEffect(() => {
    getClient();
  }, []);
  return (
    <div className="p-10" style={{}}>
      <Filter search={filter}>
        <Responsive className="p-10" m={5} l={5} xl={5}>
          <label htmlFor="">Nom :</label>
          <Input
            value={filterModel.q}
            onChange={(q) => setfilterModel((prev) => ({ ...prev, q }))}
          />
        </Responsive>
        <Responsive className="p-10" m={5} l={5} xl={5}>
          <label htmlFor="">Téléphone : </label>
          <Input
            value={filterModel.phoneNumber}
            onChange={(phoneNumber) =>
              setfilterModel((prev) => ({ ...prev, phoneNumber }))
            }
          />
        </Responsive>
      </Filter>
      <ExportAdd
        title=""
        size="md"
        noExport
        noimportExcel
        ActionOnClose={reset}
        save={save}
        AddComponent={<AddEdit model={model} _setmodel={setmodel} />}
      />
      <Grid
        actionKey="id"
        noAdvancedActions
        editAction={(id) => {
          getById(id);
          setstate((prev) => ({ ...prev, open: true }));
        }}
        columns={columns}
        rows={clients}
      />
    </div>
  );
};

export default Clients;
const columns = [
  {
    name: "Nom",
    value: "name",
    render: (v) => {
      return <p>{v}</p>;
    },
  },

  {
    name: "Téléphone",
    value: "phones",
    render: (v) => {
      return <p>{v ? v : "-"}</p>;
    },
  },
];
