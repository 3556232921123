import React, { useEffect } from "react";
import Responsive from "../../Components/Responsive";
import { Input } from "rsuite";

const AddEdit = ({ model, _setmodel }) => {
  useEffect(() => console.log(model), [model]);
  return (
    <div>
      <Responsive className="p-10" m={5} l={5} xl={5}>
        <label htmlFor="">Nom: </label>
        <Input readOnly value={model.name} />
      </Responsive>
      <Responsive className="p-10" m={5} l={5} xl={5}>
        <label htmlFor="">Numbers: </label>
        <Input
          value={model.phones}
          onChange={(phones) => _setmodel((prev) => ({ ...prev, phones }))}
        />
      </Responsive>
    </div>
  );
};

export default AddEdit;
